body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Fredoka One";
  src: local("Fredoka One"),
    url(./fonts/FredokaOne-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"),
    url(./fonts/Quicksand-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
    url(./fonts/SourceSansPro-Bold.ttf) format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

html {
  height: 100vh;
  background-color: #f7f7f7;
}
