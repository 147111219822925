.logotitle {
  font-family: "Fredoka One";
  font-size: 35px;
}

.links-list {
  display: flex;
  flex-grow: 1;
}

.link-text {
  font-size: 18px;

  font-family: "Quicksand";
  color: #616069;
}

.link-text:hover {
  color: #5e2bfa;
}

.main-navbar {
  height: 7.5vh;
}

.account-details-modal {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 150px;
  height: 50px;
  right: 8%;
  top: 5%;
  border-radius: 20px;
  outline: none;
  animation: modalSlideAccount 0.2s ease-in both;
  transform: translateX(0);
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0px 10px 0 rgba(0, 0, 0, 0.3);
}

.account-details {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes modalSlideAccount {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
