.footer-main {
  height: 7.5vh;
  width: 100%;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-socials-footer {
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
  align-items: center;
  margin-top: 2vh;
}

.home-footer-dashboard {
  border-radius: 10px;
}

.by-text-dashboard {
  font-size: 18px;
  color: #242424;
  font-weight: 600;
}

.home-name-dashboard {
  font-size: 18px;
  color: #525fff;
  font-weight: 600;
}
