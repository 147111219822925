.main-search {
  background-color: #f7f7f7;
  height: 81vh;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;
}

.search-inputs {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
}

.search-bar {
  width: 100%;
  margin-right: 1%;
  border: 1px solid #ced4da;
  background-color: #ffffff;
}

.search-result-wrapper {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-right: 1%;
}

.search-result-row-2 {
  display: flex;
  flex-direction: row;
}

.search-result-item {
  margin: 8px;
  margin-top: 20px;
  background-color: black;
  border-radius: 20px;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.search-result-item-wrapper {
  margin: 15px;
}

.search-result-item-title {
  display: flex;
  justify-content: space-between;
}

.search-result-item-subject {
  font-family: "Source Sans Pro";
  font-size: 22px;
  color: #3c3737;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 610px;
}

.search-result-item-page-no {
  font-family: "Source Sans Pro";
  font-size: 22px;
  color: #3c3737;

  align-items: center;
  justify-content: center;
}

.search-result-item-page-no-box {
  margin-left: 10px;
  color: #f7f7f7;
  background-color: #5e2bfa;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 10px;
}

.search-result-item-text-wrapper {
  display: flex;
  flex-direction: row;
}

.search-result-item-text {
  font-family: "Quicksand";
  font-size: 19px;
}

.search-result-item-status {
  width: 10px;
  border-radius: 30px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #2ecc71;
}

.search-result-list {
  width: 100%;
  height: 300;
  overflow: hidden;
  padding-top: 20px;
  max-width: 100%;
  background-color: #f7f7f7;
}

.search-result-stats {
  width: 100%;

  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #ced4da;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.search-result-stats {
  display: flex;
  flex-direction: column;
}

.search-result-stats-title {
  font-family: Quicksand;
  font-size: 19px;
  margin: 10px;
}

.search-result-stats-title-wrapper {
  display: flex;
  justify-content: center;
}

.search-result-stats-time {
  font-family: "Quicksand";
  font-size: 15px;
  display: flex;
  justify-content: center;
}

.search-result-stats-time-wrapper {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: row;
  justify-content: space-between;
}

.search-result-stats-info {
  margin-top: 15px;
  background-color: #ffffff;
  border-radius: 0px;

  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0 0px 28px 0 rgba(0, 0, 0, 0.2);
}

.search-result-stats-info-row {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: space-between;
}

.search-result-stats-info-1 {
  font-family: "Quicksand";
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
}

.search-result-stats-info-2 {
  font-family: "Quicksand";
}

.search-result-stats {
  width: 100%;
  height: 60%;
  overflow: hidden;
}

.search-result-stats-bottom-info {
}

.search-result-stats-bottom-info-title {
  font-family: "Quicksand";
  font-size: 18px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.search-result-stats-info-bottom {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 0px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0 0px 28px 0 rgba(0, 0, 0, 0.2);
}

.search-result-stats-chart-paper {
}

.pie-text {
  font-family: Quicksand;
  font-size: 19px;
  margin: 10px;
}

.testing-this {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  color: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0), 0 0px 8px 0 rgba(2, 1, 1, 0.4);
}

.refresh-selected {
  color: #2ecc71;
}
