.home-main {
  background-color: #f7f7f7;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.home-main-bottom {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.home-login-buttons {
}

.home-tagline {
  width: 60%;
}

.home-disclaimer {
  margin-top: 40px;
  font-size: 20px;
  margin-bottom: 40px;
  color: #9d9fa0;
  font-weight: 600;
}

.home-button-text {
  font-family: "Quicksand";
  font-size: 15px;
  font-weight: bold;
  margin-left: 20px;
}

.by-text {
  font-size: 20px;
  color: #242424;
  font-weight: 600;
}

.home-name {
  font-size: 20px;
  color: #525fff;
  font-weight: 600;
}

.home-socials {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.home-find-me {
  font-size: 18px;
  font-family: "Quicksand";
}
