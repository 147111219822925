#firebaseui_container {
  margin-bottom: 15px;
  min-height: 150px;
}

.firebaseui-list-item > button {
  padding: 18px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4), 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

#firebaseui_container > .mdl-card {
  border-radius: 20px;
}
