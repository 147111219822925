.main-upload {
  background-color: #f7f7f7;
  height: 81vh;
  justify-content: center;
}

.main-upload-canvas {
  margin-left: 200px;
  margin-right: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  flex-direction: column;
}

.subject-items-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 50vh;
  overflow-x: hidden;
}

.button-text {
  font-family: "Quicksand";
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}

.button-container {
  margin-bottom: 20px;
}

.subject-container {
  margin-top: 20px;
}
.subject-item {
  display: flex;

  justify-content: space-between;
  align-items: center;
  width: 600px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.01), 0 1px 10px 0 rgba(0, 0, 0, 0.07);
}

.subjectItemStatus {
  width: 7px;
  height: 50px;
  border-radius: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.subject-item-content-wrapper {
  display: flex;
  flex-direction: column;
}

.subject-info-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subject-name {
  font-family: "Fredoka One";
  font-size: 20px;
  color: #3c3737;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
}

.subject-lecture-count {
  color: #9d9fa0;
  font-weight: 600;
}

.add-subject-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.add-subject-main-inactive {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  opacity: 0.4;
}

.inactive {
  pointer-events: none;
}

.add-subject-title {
  font-family: "Quicksand";
  font-size: 25px;
  color: #3c3737;
}

.subject-submit-form {
  display: flex;
  flex-direction: column;
}

.add-subject-textfields {
  display: flex;
  align-items: center;
  width: 450px;
  justify-content: space-between;
  flex-grow: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-file-status {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.view-files-main {
  position: absolute;
  right: 50px;
  top: 20px;
  width: 300px;
  height: 94%;
  background-color: #f7f7f7;
  z-index: 100;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(120%);
  transition: transform 0.3s ease-out;
  border-radius: 10px;
  justify-content: center;
}

.side-drawer {
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  right: 50px;
  top: 20px;
  width: 300px;
  height: 94%;
  background-color: #f7f7f7;
  z-index: 100;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-out;
  transform: translateX(0);
  flex-direction: column;
}

.view-file-header {
  margin-top: 10px;
  font-family: "Fredoka One";
  font-size: 20px;
}

.file-item-content-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-width: 1px;
  border-color: #c9c9c9;
  border-radius: 10px;
  border-style: dashed;
  padding: 10px;
  margin-bottom: 5px;
}

.file-upload-name {
  font-family: "Quicksand";
  font-size: 18px;
  color: #3c3737;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
}

.file-upload-size {
  color: #9d9fa0;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.progress-main {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  flex-direction: column;
}

.progress-text {
  font-family: "Quicksand";
  font-size: 28px;
  color: #3c3737;
  margin-top: 20px;
}

.subject-item-dialog-text {
  font-family: "Quicksand";
  font-size: 20px;
}

.subject-item-dialog-text-sub {
  font-family: "Quicksand";
  font-size: 20px;
  color: #3c3737;
}

.subject-item-dialog-wrapper {
}

.subject-item-dialog-wrapper-item {
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.25);
  border-width: 1px;
  border-color: #c9c9c9;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.subject-item-dialog-wrapper-text {
  width: 180px;
  font-family: "Quicksand";
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
