.vivas-main {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  height: 81vh;

  padding-left: 100px;
  padding-right: 100px;
  flex-direction: row;
  flex-direction: column;
}

.splide__pagination {
  bottom: 0.5em;
  left: 50%;
  transform: translateX(-50%);
}

.viva-item-wrapper {
  display: flex;
  flex-direction: column;

  padding: 10px;
  margin: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.07);
}
.viva-item-wrapper-active {
  display: flex;
  flex-direction: column;

  padding: 12px;
  margin: 10px;
  animation-name: mymove 5s;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.07);
}

@keyframes mymove {
  from {
    background-color: #ffffff;
  }
  to {
    background-color: #e2e2e2;
  }
}

.viva-item-title {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 5px;
  font-family: "Quicksand";
  font-size: 18px;
  color: #3c3737;
}

.viva-item-title-answer {
  font-family: "Quicksand";
  font-size: 15px;
  color: #3c3737;
}

.vivas-add {
  height: 100px;
  width: 100px;
  background-color: black;
}

.vivas-conatainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.viva-add-main {
  display: flex;
  justify-content: center;
  width: 380px;
  padding: 10px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5), 0 0px 10px 0 rgba(0, 0, 0, 0.4);
}

.viva-add-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.viva-add-title {
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  display: flex;
  align-items: center;
}

.viva-add-items {
  width: 300px;
  height: 120px;
  display: flex;
  flex-direction: row;
}

.viva-add-item {
  width: 90%;
  background-color: red;
  flex-direction: row;
  margin: 5px;
  border-radius: 20px;
  background-color: #f7fafc;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #3c3737;
  cursor: pointer;
}

.viva-add-item:hover {
  background-color: #eaeaeb;
}

.viva-add-item:active {
  background-color: #d0cccc;
  box-shadow: 0 0 0 white;
  border: 0px solid #41a0ff;
}

.viva-add-wrapper-issues {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viva-add-wrapper-issues-main {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mymodalviva {
  position: absolute;
  bottom: 30%;
  right: 2%;
  /* border: 1px solid #ccc; */
  border-radius: 20px;
  outline: none;

  animation: modalSlide 0.2s ease-out forwards;
  transform: translateY(0);
}
@keyframes modalSlide {
  from {
    transform: translateY(-20%);
    opacity: 0;
  }

  to {
    transform: translateY(2%);
    opacity: 1;
  }
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 15px;
}

.vivas-audio-wrapper {
  width: 1000px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.vivas-audio-buttons {
  margin-top: 10px;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.vivas-voice-search {
  font-family: "Quicksand";
  font-size: 22px;
  color: #3c3737;
  margin-top: 15px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vivas-voice-search-text {
  width: "100px";
  display: flex;
  justify-content: center;
  align-items: center;
}

.viva-result-item {
  margin-top: 20px;
  margin-left: 20px;
  width: 350px;
  height: 50%;
  background-color: black;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.viva-sesult-wrapper {
  height: 31vh;
  overflow: scroll;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.vivas-settings {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 50px;
  height: 250px;
  border-radius: 30px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 1%;
}

.warning-viva {
  font-family: "Quicksand";
  font-size: 13px;
  margin-top: 10px;
  color: #ff1744;
}
